<template>
  <div>
    <!-- S:Bread crumbs -->
    <v-row>
      <v-col sm="12" md="12" lg="12" class="pa-10 pb-2">
        <v-card elevation="1" class="containerbox">
          <breadcrumbComp class="hidden-sm-and-down" :mainPage="mainPage" :subPage="subPage" :backSlash="backSlash" :pageUrl="pageUrl"
            :showAdd="showAdd" @addFav="addFav()" :refresh="refresh" :resetFunction="resetFunction" />
          <!--  Bread Crumb for mobile -->
          <breadcrumbComp class="hidden-md-and-up" :subPage="subPage" :pageUrl="pageUrl" :showAdd="showAdd" @addFav="addFav()" :refresh="refresh"
            :resetFunction="resetFunction" />
          <!-- E:Breadcrumbs -->
          <!-- Search Card -->
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" class="pa-4">
              <v-card outlined color="transparent" class="pa-0 ma-0 containerbox pb-5">
                <v-card-text class="pb-0 pt-0">
                  <v-form ref="projectForm" v-model="isFormValid" class="pa-2 pr-5" lazy-validation>
                    <v-row class="pt-5">
                      <!-- For Company ID -->
                      <v-col cols="12" sm="12" md="12" lg="11" class="px-5 py-0">
                        <v-text-field color="primarytext" v-model="projectName" label="Search" @keydown.enter="getSearchProjectList" outlined
                          clearable dense @input="projectName=projectName?.toUpperCase()" class="ma-0" :rules="max100Rule" persistent-placeholder>
                        </v-text-field>
                      </v-col>
                      <!-- Active -->
                      <v-col cols="12" sm="12" md="12" lg="1" class="px-5 py-0">
                        <v-switch class="activeSwitch ma-2 d-inline-block" v-model="active" :label="active ? 'Active' : 'Inactive'" input-value="true"
                          color="primarytext" hide-details inset></v-switch>
                      </v-col>
                    </v-row>
                    <!-- <v-row>
                      <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
                        <v-autocomplete color="primarytext" attach outlined v-model="projectCode" :items="projectData" item-text="Project"
                          item-value="ProjKey" label="Project" dense persistent-placeholder>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0">
                        <v-text-field color="primarytext" v-model="projectOwner" label="Project Owner" @keydown.enter="getSearchProjectList" outlined
                          clearable dense class="ma-0" :rules="max100Rule" persistent-placeholder>
                        </v-text-field>
                      </v-col> 
                 
                     
                    </v-row>-->
                    <v-row>
                      <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0"></v-col>
                      <!--  Search Button and cancel -->
                      <v-col cols="12" sm="12" md="12" lg="2" class="px-5 py-0">
                        <v-btn :disabled="!isFormValid" color="primarytext" outlined class="ma-2 btn-style" elevation="1"
                          @click="getSearchProjectList()">
                          <v-icon dark left> mdi-magnify </v-icon> Search Project
                        </v-btn>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="2" class="px-5 py-0">
                        <v-btn color="secondary" outlined dense class="ma-2 btn-style" elevation="1" @click="onSearchCancel()">
                          <v-icon dark left> mdi-magnify-close </v-icon>Clear Search
                        </v-btn>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" lg="4" class="px-5 py-0"></v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!-- Table -->
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" class="pt-5 pa-10">
        <v-card outlined class="containerbox">
          <v-card-text class="pb-0 pt-0">
            <v-row>
              <v-col cols="12" sm="6" md="6" lg="9" class="px-5 pt-10 pl-8">
                <span color="primarytext">
                  No.of Records: {{ totalRecords }}
                </span>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="3" class="px-2 ma-0 pt-6 pr-8">
                <!-- Button to add user -->
                <v-btn class="my-2 mx-0 btn-style float-right px-2 success" elevation="1" outlined color="cWhite" @click="newProjectClicked()"
                  v-if="actionSetter('AddProject')">
                  <v-icon dark left> mdi-plus </v-icon>New Project
                </v-btn>
                <!-- Company add Comp -->
                <export-excel class="btn btn-default" :data="projectList" :fields="json_fields" worksheet="Sheet1" :name="excelName"
                  v-if="actionSetter('ExportProject') && !exportDisable">
                  <!-- Button to add user -->
                  <v-btn :disabled="exportDisable" class="ma-2 btn-style float-right" elevation="1" outlined color="secondary" @click="fileName()">
                    <v-icon dark left> mdi-file-export </v-icon>Export
                  </v-btn>
                </export-excel>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12" class="pa-0 pl-8 pr-8 pb-5">
                <v-data-table :headers="headers" :items="projectList" item-key="proj_id">
                  <template v-slot:item.project="{ item }">
                    <a @click="projectDetails(item)" v-bind:class="($vuetify.theme.dark) ? 'anchorDark' : 'anchorlight'">
                      {{ item.project }}
                    </a>
                  </template>

                  <template v-slot:item.active="{ item }">
                    <span class="active" v-if="item.active == true">Active</span>
                    <span class="inactive" v-else>Inactive</span>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="projectDetails(item)">
                      mdi-eye
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col></v-row>
    <projectComp v-if="compCalled" :name="name" :compCalled="compCalled" @hide="close()" :projectId="projectId" @projectChange="recallProjList()"
      :pprojectName="pprojectName" :pprojectCode="pprojectCode" :pactive="isActive" :lp_prefix="lp_prefix" :lpn_active="lpn_active"
      :sl_by_sbu="sl_by_sbu" :notes="notes" :pcompany="pcompany" :actionList="actionList" />
  </div>
</template>

<script>
import Vue from 'vue';
import excel from 'vue-excel-export';
import breadcrumbComp from '../common/breadcrumb-comp.vue';
import EncryptUtility from '../utility/js/encrypt-utility';
import Utility from '../../shared/utility';
import projectComp from '../company/app-project-comp.vue';
import AddFavourite from '../utility/js/addFav';
import commonAPIService from "../common/js/common-api-service";
Vue.use(excel);
export default {
  data: () => ({
    max100Rule: [
      (v) => (v || '').length <= 100 || 'Max Length of 100 character',
    ],
    name: 'newProject',
    projectOwner: '',
    active: true,
    favouriteList: [],
    showAdd: false,
    exportDisable: true,
    pprojectName: '',
    backSlash: true,
    mainPage: '',
    subPage: '',
    pageUrl: '',
    excelName: '',
    pprojectCode: '',
    projectId: '',
    lp_prefix: '',
    notes: '',
    isFormValid: false,
    pcompany: '',
    lpn_active: false,
    sl_by_sbu: false,
    compCalled: false,
    projectName: '',
    projectCode: '',
    isActive: false,
    userId: EncryptUtility.localStorageDecrypt('userID'),
    projectList: [],
    totalRecords: 0,
    json_fields: {
      ProjectName: 'project',
      ProjectCode: 'proj_code',
      ProjectOwner: 'owner_key',
      Licence_Plate_Prefix: 'lp_prefix',
      Status: 'active',
    },
    json_meta: [
      [
        {
          key: 'charset',
          value: 'utf-8',
        },
      ],
    ],
    headers: [
      {
        text: 'Project Name',
        align: 'start',
        value: 'project',
        class: 'primary customwhite--text',
      },
      {
        text: 'Project Code',
        value: 'proj_code',
        class: 'primary customwhite--text',
      },
      {
        text: 'Project Owner',
        value: 'owner_key',
        class: 'primary customwhite--text',
      },
      {
        text: 'Licence Plate Prefix',
        value: 'lp_prefix',
        class: 'primary customwhite--text',
      },
      { text: 'Status', value: 'active', class: 'primary customwhite--text' },
      {
        text: 'Actions',
        value: 'actions',
        class: 'primary customwhite--text',
        sortable: false,
      },
    ],
    actionList: [],
    route: '',
    projectData: [],
    refresh: true,
  }),
  async created() {
    this.route = this.$route.path.replace('/', '');
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {
    this.getProjectList();
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.compCalled = false;
      this.projectName = "";
      this.projectCode = "";
      this.projectData = [];
      this.projectOwner = "";
      this.active = true;
      this.totalRecords = 0;
      this.projectList = [];
      this.$refs.projectForm.resetValidation();
      this.getProjectList();
      this.exportDisable = true;
    },
    //To prepopulate the list of project
    projectDetails(item) {
      this.compCalled = true;
      this.name = 'editProject';
      this.projectId = item.project_id;
      this.isActive = item.active;
      this.pprojectName = item.project;
      this.pprojectCode = item.proj_code;
      this.lp_prefix = item.lp_prefix;
      this.lpn_active = item.lpn_active;
      this.sl_by_sbu = item.sl_by_sbu;
      this.pcompany = item.owner_info;
      this.notes = item.notes;
    },
    //On click of search cancel
    onSearchCancel() {
      this.projectName = '';
      this.projectCode = '';
      this.projectOwner = '';
      this.active = true;
      this.projectData = [];
      this.totalRecords = 0;
      this.projectList = [];
      this.exportDisable = true;
      this.getProjectList();
    },
    //To close the dialog box
    close() {
      this.name = '';
      this.compCalled = false;
    },
    //To close dialog popups and call project API once again
    recallProjList() {
      this.name = '';
      this.compCalled = false;
      //Calling the search API once New Project is created
      this.getSearchProjectList();
    },
    //File name
    fileName() {
      this.excelName =
        'Project_List_' +
        new Date()
          .toISOString()
          .replace('T', '_')
          .replace('Z', '')
          .replace('.', '')
          .replaceAll('-', '_') +
        '.xls';
    },
    //Get Project List
    async getProjectList() {
      this.projectData = [];
      let data = await commonAPIService.getProject("get", this.userId);
      if (data.message !== "NA") {
        this.projectData = data.ProjectInfo;
      }
    },
    //Get the Project Data
    getSearchProjectList() {
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
      let searchObject = {
        ProjectCode: "",
        ProjectName: this.projectName,
        ProjectOwner: this.projectOwner,
        strActive: this.active ? '1' : '0',
        UserId: this.userId,
        Proj_id: (this.projectCode == "" || this.projectCode == null) ? 0 : parseInt(this.projectCode),
      };
      this.totalRecords = 0;
      this.axios
        .post('/cp/project_list', searchObject)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = JSON.parse(response.data.body.message);
            this.projectList = responseData;
            this.totalRecords = responseData.length;
            this.exportDisable = false;
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
            this.exportDisable = true;
            this.projectList = [];
            let Alert = {
              type: 'error',
              isShow: true,
              message: response.data.message,
            };
            this.editMenuDialog = false;
            this.$store.commit('ConfigModule/Alert', Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
          this.projectList = [];
          this.exportDisable = true;

          let Alert = {
            type: 'error',
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit('ConfigModule/Alert', Alert);
          this.errorMessage = error.message;
        });
    },
    //New Project button click event
    newProjectClicked() {
      this.compCalled = true;
      this.name = 'newProject';
    },
    //To set the action
    actionSetter(action) {
      if (this.actionList !== null && this.actionList.length > 0) {
        let filter = this.actionList.filter(
          (entry) => entry.SystemName === action,
        );
        return filter !== undefined && filter !== null && filter.length == 1
          ? true
          : false;
      } else return false;
    },
    //On Click of Favorite icon
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
    projectComp,
  },
};
</script>

<style scoped>
.active {
  background-color: #2ed8b6;
  border-radius: 5px;
  padding: 5px;
  color: white;
}

.inactive {
  background-color: #ff5370;
  border-radius: 5px;
  padding: 5px;
  color: white;
}
</style>