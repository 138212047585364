import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import companyComp from "../../common/common-company-comp-existing.vue";
export default {
  name: "projectComp",
  props: {
    name: String,
    compCalled: Boolean,
    projectId: String,
    pprojectName: String,
    pprojectCode: String,
    pactive: Boolean,
    lp_prefix: String,
    lpn_active: Boolean,
    sl_by_sbu: Boolean,
    pcompany: String,
    notes: String,
    actionList: Array,
  },
  data: () => ({
    max50Rule: [(v) => (v || "").length <= 50 || "Max Length of 50 character", (v) => !!v || "Field is required",
    (v) => (v || "").length >= 3 || "Min Length of 3 character"],
    max10Rule: [(v) => (v || "").length <= 10 || "Max Length of 10 character", (v) => (v || "").length >= 3 || "Min Length of 3 character", (v) => !!v || "Field is required"],
    panel: 0,
    required: [(v) => !!v || "Field is required"],
    alreadyTakenProj: [(v) => v + " already exists" || "Project name already exists"],
    alreadyTakenProjCode: [(v) => v + " already exists" || "Project code already exists"],
    serviceLevel: false,
    rlpIdPrefix: "LP",
    addCompanyCalled: false,
    trackByLPN: true,
    set_default_proj: false,
    outLineText: "",
    projectCode: "",
    showUpdate: false,
    disableAdd: true,
    projectName: "",
    active: true,
    phone1: "",
    title: "",
    deleteProjectDialog: false,
    refreshCompList: false,
    edit: false,
    contactName: "",
    selectedCountry: "",
    userId: EncryptUtility.localStorageDecrypt("userID"),
    selectedState: "",
    description: "",
    fax: "",
    cell: "",
    phone2: "",
    selectedCompany: "",
    ownerKey: "",
    companyId: [],
    stateData: [],
    countryData: [],
  }),
  mounted() {
    this.getCompanyList(); //List
    if (this.name !== "newProject") {
      this.projectName = this.pprojectName;
      this.active = this.pactive;
      this.projectCode = this.pprojectCode;
      this.rlpIdPrefix = this.lp_prefix;
      this.trackByLPN = this.lpn_active;
      this.serviceLevel = this.sl_by_sbu;
      this.description = this.notes;
      this.selectedCompany = this.pcompany;
    }
  },
  methods: {
    disableTextbox() {
      if (this.outLineText == "success" && this.projectName.length > 2 && this.projectCode.length > 2) {
        this.disableAdd = false
      }
      else {
        this.disableAdd = true
      }
      this.outLineText = ""
    },
    refreshCompany(checkCompany, refresh, custno) {
      this.set_default_proj = checkCompany;
      this.refreshCompList = refresh;
      if (this.refreshCompList) {
        this.selectedCompany = custno;
        this.getCompanyList();
      }
    },
    // to close the diaglog box
    closeCompany() {
      this.addCompanyCalled = false;
      this.showUpdate = false;
    },
    //To make all the characters captial
    uppercase() {
      this.rlpIdPrefix = this.rlpIdPrefix.toUpperCase();
    },
    //Select Company
    onselectCompanyId(selectedCompany) {
      let companySelected = this.companyId?.find((element) => element.comp_id == selectedCompany);
      if (companySelected !== undefined) {
        this.companyName = companySelected.company;
        this.address1 = companySelected.address1;
        this.address2 = companySelected.address2;
        this.city = companySelected.city;
        this.selectedState = companySelected.state;
        this.postal = companySelected.Zip;
        this.selectedCountry = companySelected.country;
        this.contactName = companySelected.ContName;
        this.title = companySelected.title;
        this.email = companySelected.email;
        this.phone1 = companySelected.phone1;
        this.phone2 = companySelected.phone2;
        this.cell = companySelected.cell;
        this.fax = companySelected.fax;
        this.ownerKey = companySelected.comp_id;
      }
    },


    //To Cancel
    onClickCancel() {
      this.compCalled = false;
      this.$emit("hide");
      this.deleteProjectDialog = false;
    },
    //Allow Edit
    allowEdit() {
      this.edit = true;
      this.showUpdate = true;
    },
    //Open delete Dialog
    deleteDialog() {
      this.deleteProjectDialog = true;
    },
    //Delete Proejct
    deleteProject() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      let deleteproject = {
        ProjectId: this.projectId,
        UserId: this.userId,
      };
      this.axios
        .post("/cp/project_delete", deleteproject)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.message,
            };
            this.deleteCompanyDialog = false;
            this.compCalled = false;
            this.$emit("projectChange");
            this.deleteProjectDialog = false;
            this.$store.commit("ConfigModule/Alert", Alert);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.deleteMenuDialog = false;
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Company Id Dropdown
    getCompanyList() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`/cp/company_details?pass=3&user_id=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message).CompanyInfo;
            this.companyId = responseData;
            if (this.name !== "newProject" && this.pcompany) {

              let company = this.companyId.find((element) => element.custno == this.pcompany);
              if (company) {
                this.pcompany = company?.comp_id;
                this.selectedCompany = company?.comp_id;
                this.onselectCompanyId(this.pcompany);
              }
              else {
                this.onselectCompanyId(this.selectedCompany)
              }
            }
            this.refreshCompList ? this.onselectCompanyId(this.selectedCompany) : "";
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },

    //Add Project
    onClickAdd() {
      if (this.$refs.projectAddForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let projectData = {
          Project: this.projectName,
          Active: this.active,
          Proj_Code: this.projectCode,
          Notes: this.description,
          Owner_Key: this.ownerKey,
          Lpn_Active: this.trackByLPN,
          Sl_By_Sbu: this.serviceLevel,
          UserId: parseInt(this.userId),
          Lp_Prefix: this.rlpIdPrefix,
          set_default_proj: this.set_default_proj,
        };
        {
          this.axios
            .post("/cp/project_create", projectData)
            .then((response) => {
              if (response.status == 200 || response.status == 204) {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                this.compCalled = false;
                this.$emit("projectChange");
                this.deleteProjectDialog = false;
                let Alert = {
                  type: "success",
                  isShow: true,
                  message: response.data.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              } else {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: response.body.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            })
            .catch((error) => {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.errorMessage = error.message;
            });
        }
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Please fill the form",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Update
    onClickEdit() {
      if (this.$refs.projectAddForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        let projectData = {
          ProjectId: this.projectId,
          Project: this.projectName,
          Active: this.active,
          Proj_Code: this.projectCode,
          Notes: this.description,
          Owner_Key: this.ownerKey,
          set_default_proj: this.set_default_proj,
          Lpn_Active: this.trackByLPN,
          Sl_By_Sbu: this.serviceLevel,
          UserId: this.userId,
          Lp_Prefix: this.rlpIdPrefix,

        };

        {
          this.axios
            .post("/cp/project_update", projectData)
            .then((response) => {
              if (response.status == 200 || response.status == 204) {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "success",
                  isShow: true,
                  message: response.data.message,
                };
                this.compCalled = false;
                this.$emit("projectChange");
                this.deleteProjectDialog = false;
                this.$store.commit("ConfigModule/Alert", Alert);
              } else {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: response.data.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            })
            .catch((error) => {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.errorMessage = error.message;
            });
        }
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Please fill the form",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Close the dialog
    // To check if the user has access access to that action or not
    actionSetter(action) {
      if (this.actionList !== null && this.actionList.length > 0) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },

    checkProject(pass, value) {
      if (pass == 2) {
        this.projectCode = this.projectCode.toUpperCase()
      }
      if (value.length > 2) {
        {
          let projObj = {
            user_id: this.userId,
            pass: pass == 1 ? 1 : 2,
            proj_code: pass == 1 ? "" : value,
            project: pass == 1 ? value : ""
          }
          this.axios
            .post("/cp/project_check_unique", projObj)
            .then((response) => {
              if (response.status == 200 || response.status == 204) {

                this.outLineText = "success"
              } else {
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: response.data.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
                this.outLineText = "error"
              }
            })
            .catch((error) => {
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.outLineText = "error"
              this.$store.commit("ConfigModule/Alert", Alert);
              this.errorMessage = error.message;
            });
        }
      }
    },


  },
  components: {
    companyComp,
  },
};
